import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'

import { CraftCategoryBannersFragment } from '../lib/craftFragments.generated'
import theme from '../theme'
import CraftImage from './CraftImage'
import CraftLink from './CraftLink'

const bannerStyling = css`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: end;
  height: 100%;
  min-height: 200px;
`

const Wrapper = styled.div`
  ${bannerStyling};
`

const StyledCraftLink = styled(CraftLink)`
  text-decoration: none;
  ${bannerStyling};

  &:hover {
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    ${theme.animation.zoomNestedImage(
      1.05,
      theme.transition.durations.extraSlow,
    )};
  }
`

const Figure = styled.figure`
  grid-row: 1;
  grid-column: 1 / span 12;
  margin: 0;
  height: 100%;
  ${theme.stylingSnippet.transparentGradientOverlay()};
`

const StyledCraftImage = styled(CraftImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Content = styled.div`
  grid-row: 1;
  grid-column: 1 / span 12;
  padding: ${theme.spacing.lg};
  position: relative;
  z-index: 1;
`

const Title = styled(H3)`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  color: ${theme.colors.textLight};
`

const Subtitle = styled.span`
  color: ${theme.colors.textLight};
`

interface WrapperProps
  extends Pick<CraftCategoryBannersFragment, 'bannerLink' | 'bannerTitle'> {
  children: ReactNode
}

const BannerWrapper = ({ bannerLink, bannerTitle, children }: WrapperProps) =>
  bannerLink?.url ? (
    <StyledCraftLink
      craftLink={bannerLink}
      analyticsContext="highlightBanner"
      analyticsName={bannerTitle ?? undefined}
    >
      {children}
    </StyledCraftLink>
  ) : (
    <Wrapper>{children}</Wrapper>
  )

interface Props {
  banner: CraftCategoryBannersFragment
}

const CategoryPromotionBanner = ({ banner }: Props) => {
  const image = banner.image?.[0]

  return image?.url ? (
    <BannerWrapper
      bannerLink={banner.bannerLink}
      bannerTitle={banner.bannerTitle}
    >
      <Figure>
        <StyledCraftImage
          image={image}
          sizes={theme.imageSizes.productCard.sizes}
        />
      </Figure>

      <Content>
        {banner.bannerTitle && <Title>{banner.bannerTitle}</Title>}

        {banner.subtitle && <Subtitle>{banner.subtitle}</Subtitle>}
      </Content>
    </BannerWrapper>
  ) : null
}

export default CategoryPromotionBanner
